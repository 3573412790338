<template>
  <div class="container-fluid container-fixed-lg bg-white app-table-container">
    <ConfirmModal
      ref="modal_confirm_cancel"
      :modal-header-title="$t('common.confirm_cancel_title')"
      :confirm-handle="redirectBack">
    </ConfirmModal>
    <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
      <form novalidate autocomplete="off">
        <div class="control-label input-label justify-content-left" style="margin-left:8px; padding-top: 40px" v-if="furusato_coupon.id">
          {{$t('furusato_coupon.edit')}}
        </div>
        <div class="control-label input-label justify-content-left" style="margin-left:8px; padding-top: 40px" v-else>
          {{$t('nav.furusato_coupon_create')}}
        </div>
        <div class="row" style="margin-top:20px;">
          <div class="col-md-6" style="margin-bottom:10px">
            <AppInput
              type="string"
              name="master_code"
              :label="$t('furusato_coupon.coupon_code')"
              input-style="normal"
              rules="required|max:191"
              :disabled="idEditing"
              v-model="furusato_coupon.code"
            />
          </div>
          <div class="col-md-6" style="margin-bottom:10px"></div>
          <div class="col-md-6 m-b-10">
            <AppSelect
              :label="$t('furusato_product.furusato_city_id')"
              input-style="normal"
              name="full_city_id"
              boundaryClass="m-auto"
              rules="required"
              :options="{containerCssClass: 'z-index-0'}"
              :options-data="options_cities"
              :value_empty="false"
              v-model="furusato_coupon.full_city_id"
              :disabled="owners.length > 0  ? true : false"
            />
          </div>
          <div class="col-md-6" style="margin-bottom:10px"></div>

          <div class="col-md-6">
            <div class="col-md-12 p-0">
              <label class="control-label input-label p-b-10" style="margin-top: 15px;">{{$t('furusato_coupon.type')}}</label>
              <span class="color-active app-text-bold"> ＊</span>
            </div>
            <div class="col-md-12 p-0">
              <div class="row">
                <div class="icheck-primary d-inline" style="padding-right: 20px;">
                  <input type="radio" name="category" class="mr-2" v-if="furusato_coupon.use_type == $consts.INPUT.TYPE_DISCOUNT_PRICE"
                         checked @click="handleInput($consts.INPUT.TYPE_DISCOUNT_PRICE)" :disabled="owners.length > 0  ? true : false">
                  <input type="radio" name="category" class="mr-2" v-else @click="handleInput($consts.INPUT.TYPE_DISCOUNT_PRICE)" :disabled="owners.length > 0  ? true : false">
                  <label class="control-label input-label p-0">{{$t('furusato_coupon.type_coupon')}}</label>
                </div>
              </div>
            </div>
            <div class="col-md-12 p-0">
              <div class="row">
                <div class="col-md-2 p-0">
                  <label class="control-label input-label" style="margin-top: 15px; margin-left: 20px;">{{$t('furusato_coupon.discount_price')}}</label>
                </div>
                <div class="col-md-10 p-0">
                  <div class="form-group">
                    <div class="form-group m-b-0 p-0" v-if="furusato_coupon.use_type == $consts.INPUT.TYPE_DISCOUNT_PRICE">
                      <AppInput v-if="idEditing && owners.length > 0"
                        :disabled="true"
                        type="bigint"
                        name="price"
                        input-style="normal"
                        rules="required|min_value:0|max_value:4294967295"
                        v-model="furusato_coupon.price"
                      />
                      <AppInput v-else
                        :disabled="furusato_coupon.use_type == $consts.INPUT.TYPE_PRODUCT_PRICE
                        || furusato_coupon.use_type == $consts.INPUT.TYPE_DISCOUNT_DEFAULT"
                        type="bigint"
                        name="price"
                        input-style="normal"
                        rules="required|min_value:0|max_value:4294967295"
                        v-model="furusato_coupon.price"
                      />
                    </div>
                    <div class="form-group m-b-0 p-0" v-else>
                      <AppInput
                        :disabled="furusato_coupon.use_type == $consts.INPUT.TYPE_PRODUCT_PRICE ||
                        furusato_coupon.use_type == $consts.INPUT.TYPE_DISCOUNT_DEFAULT"
                        type="bigint"
                        name="price"
                        input-style="normal"
                      />
                    </div>
                  </div>
                  <label class="error app-input-error-label-2"
                         v-if="furusato_coupon.price == '' && furusato_coupon.use_type == $consts.INPUT.TYPE_DISCOUNT_PRICE && submit == true">
                    こちらは必須項目です。
                  </label>
                </div>
              </div>
            </div>

            <div class="col-md-12 p-0">
              <div class="row">
                <div class="icheck-primary d-inline" style="padding-right: 20px;">
                  <input type="radio" name="category" class="mr-2" v-if="furusato_coupon.use_type == $consts.INPUT.TYPE_PRODUCT_PRICE"
                         checked @click="handleInput($consts.INPUT.TYPE_PRODUCT_PRICE)" :disabled="owners.length > 0  ? true : false">
                  <input type="radio" name="category" class="mr-2" v-else @click="handleInput($consts.INPUT.TYPE_PRODUCT_PRICE)" :disabled="owners.length > 0  ? true : false">
                  <label class="control-label input-label p-0">{{$t('furusato_coupon.type_tour_ticket')}}</label>
                </div>
              </div>
            </div>
            <div class="col-md-12 p-0">
              <div class="row">
                <div class="col-md-2 p-0">
                  <label class="control-label input-label p-0" style="margin-top: 15px; margin-left: 20px;">{{$t('furusato_coupon.product')}}</label>
                </div>
                <div class="col-md-10 p-0">
                  <div class="form-group">
                    <div class="form-group m-b-0 p-0" v-if="furusato_coupon.use_type == $consts.INPUT.TYPE_PRODUCT_PRICE">
                      <AppSelect v-if="idEditing && owners.length > 0"
                        :disabled="true"
                        input-style="normal"
                        name="commodity_id"
                        boundaryClass="m-auto"
                        :options="{containerCssClass: 'z-index-0'}"
                        :options-data="options_commodities"
                        :value_empty="false"
                        v-model="furusato_coupon.commodity_id"
                      />
                      <AppSelect v-else
                        :disabled="furusato_coupon.use_type == $consts.INPUT.TYPE_DISCOUNT_DEFAULT || !furusato_coupon.use_type"
                        input-style="normal"
                        name="commodity_id"
                        boundaryClass="m-auto"
                        :options="{containerCssClass: 'z-index-0'}"
                        :options-data="options_commodities"
                        :value_empty="false"
                        v-model="furusato_coupon.commodity_id"
                      />
                    </div>
                    <div class="form-group m-b-0 p-0" v-else>
                      <AppSelect
                        :disabled="furusato_coupon.use_type == $consts.INPUT.TYPE_DISCOUNT_DEFAULT || !furusato_coupon.use_type"
                        input-style="normal"
                        name="commodity_id"
                        boundaryClass="m-auto"
                        :options="{containerCssClass: 'z-index-0'}"
                        :options-data="options_commodities"
                        :value_empty="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6" style="margin-bottom:10px"></div>
          <div class="col-md-6" style="margin-bottom:10px">
            <AppInput
              type="string"
              name="price"
              :label="$t('furusato_coupon.name_coupon')"
              input-style="normal"
              rules="required|max:191"
              v-model="furusato_coupon.name"
              :disabled="owners.length > 0  ? true : false"
            />
          </div>
          <div class="col-md-6" style="margin-bottom:10px"></div>
          <div class="col-md-6" style="margin-bottom:10px">
            <AppSelect
              input-style="normal"
              name="publish_num"
              :label="$t('furusato_coupon.publish_num')"
              boundaryClass="m-auto"
              :options="{containerCssClass: 'z-index-0'}"
              :options-data="options_nums"
              :value_empty="false"
              rules="required"
              v-model="furusato_coupon.publish_num"
            />
          </div>

          <div class="col-md-6" style="margin-bottom:10px"></div>
          <div class="col-md-6 col-lg-6 row p-b-20">
            <div class="col-md-12 p-0">
              <label class="control-label input-label p-0"
                     style="margin-top:15px;">{{
                $t('furusato_coupon.reception_date') }}</label>
              <span class="color-active app-text-bold"> ＊</span>
            </div>
            <div class="col-md-1 p-0">
              <label class="control-label input-label p-0"
                     style="margin-top:15px;">{{
                $t('furusato_coupon.start_date') }}</label>
            </div>
            <div class="col-md-2 col-15-coupon-datetime">
              <AppSelect
                input-style="normal"
                name="publish_at_yy"
                boundaryClass="m-auto"
                :options="{containerCssClass: 'z-index-0'}"
                :options-data="options_years"
                :value_empty="false"
                rules="required"
                v-model="furusato_coupon.publish_at_yy"
              />
            </div>
            <label class="input-label label-datetime-margin">年</label>
            <div class="col-md-2 col-15-coupon-datetime">
              <AppSelect
                input-style="normal"
                name="publish_at_mm"
                boundaryClass="m-auto"
                :options="{containerCssClass: 'z-index-0'}"
                :options-data="options_months"
                :value_empty="false"
                rules="required"
                v-model="furusato_coupon.publish_at_mm"
                @change="getPublishDays(furusato_coupon.publish_at_mm)"
              />
            </div>
            <label class="input-label label-datetime-margin">月</label>
            <div class="col-md-2 col-15-coupon-datetime">
              <AppSelect
                input-style="normal"
                name="publish_at_dd"
                boundaryClass="m-auto"
                :options="{containerCssClass: 'z-index-0'}"
                :options-data="options_publish_days"
                :value_empty="false"
                v-model="furusato_coupon.publish_at_dd"
                rules="required"
              />
            </div>
            <label class="input-label label-datetime-margin">日</label>
            <div class="col-md-2 col-15-coupon-datetime">
              <AppSelect
                input-style="normal"
                name="publish_at_hour"
                boundaryClass="m-auto"
                :options="{containerCssClass: 'z-index-0'}"
                :options-data="options_hours"
                :value_empty="false"
                rules="required"
                v-model="furusato_coupon.publish_at_hour"
              />
            </div>
            <label class="input-label mt-3">:</label>
            <div class="col-md-2 col-15-coupon-datetime">
              <AppSelect
                v-model="furusato_coupon.publish_at_minute"
                input-style="normal"
                name="publish_at_minute"
                boundaryClass="m-auto"
                :options="{containerCssClass: 'z-index-0'}"
                :options-data="options_minutes"
                :value_empty="false"
                rules="required"
              />
            </div>
          </div>

          <div class="col-md-6" style="margin-bottom:10px"></div>
          <div class="col-md-12 col-lg-6 row">
            <div class="col-md-1 p-0">
              <label class="control-label input-label p-0"
                     style="margin-top:15px;">{{
                $t('furusato_coupon.end_date') }}</label>
            </div>
            <div class="col-md-2 col-15-coupon-datetime">
              <AppSelect
                v-model="furusato_coupon.expire_at_yy"
                input-style="normal"
                name="expire_at_yy"
                boundaryClass="m-auto"
                :options="{containerCssClass: 'z-index-0'}"
                :options-data="options_years"
                :value_empty="false"
                rules="required"
              />
            </div>
            <label class="input-label label-datetime-margin">年</label>
            <div class="col-md-2 col-15-coupon-datetime">
              <AppSelect
                v-model="furusato_coupon.expire_at_mm"
                input-style="normal"
                name="expire_at_mm"
                boundaryClass="m-auto"
                :options="{containerCssClass: 'z-index-0'}"
                :options-data="options_months"
                :value_empty="false"
                rules="required"
                @change="getExpireDays(furusato_coupon.expire_at_mm)"
              />
            </div>
            <label class="input-label label-datetime-margin">月</label>
            <div class="col-md-2 col-15-coupon-datetime">
              <AppSelect
                v-model="furusato_coupon.expire_at_dd"
                input-style="normal"
                name="expire_at_dd"
                boundaryClass="m-auto"
                :options="{containerCssClass: 'z-index-0'}"
                :options-data="options_expire_days"
                :value_empty="false"
                rules="required"
              />
            </div>
            <label class="input-label label-datetime-margin">日</label>
            <div class="col-md-2 col-15-coupon-datetime">
              <AppSelect
                v-model="furusato_coupon.expire_at_hour"
                input-style="normal"
                name="expire_at_hour"
                boundaryClass="m-auto"
                :options="{containerCssClass: 'z-index-0'}"
                :options-data="options_hours"
                :value_empty="false"
                rules="required"
              />
            </div>
            <label class="input-label mt-3">:</label>
            <div class="col-md-2 p-b-20 col-15-coupon-datetime">
              <AppSelect
                v-model="furusato_coupon.expire_at_minute"
                input-style="normal"
                name="expire_at_minute"
                boundaryClass="m-auto"
                :options="{containerCssClass: 'z-index-0'}"
                :options-data="options_minutes"
                :value_empty="false"
                rules="required"
              />
            </div>
            <div class="col-md-6 pt-2">
              <label class="error app-input-error-label-2" v-if="time_publish > time_expire">
                開始日は終了日より前かと同じ日付になります。
              </label>
              <label class="error app-input-error-label-2" v-if="time_expire < new Date().valueOf()">
                終了日は今日より後の日付を入力してください。
              </label>
              <label class="error app-input-error-label-2" v-if="(time_publish && publishDateIsValid == false) ||
               (time_expire && expireDateIsValid == false)">
                正しい日付を選択してください。
              </label>
            </div>
          </div>

        </div>
        <div class="m-t-10 row justify-content-end btn-spacing-bottom">
          <button type="button"
                  class="btn btn-default m-r-10" @click="confirmCancel">
            {{ $t('common.cancel') }}
          </button>
          <button :disabled="condition_disable"
                  class="btn btn-complete m-r-10" type="button" @click="saveFurusatoCoupon($consts.INPUT.TYPE_PUBLIC)">
            {{ $t('common.public_btn') }}
          </button>
          <div v-if="furusato_coupon.id">
            <button class="btn btn-complete m-r-10 button-disable" disabled type="button" v-if="owners.length > 0">
              {{ $t('common.draft_btn') }}
            </button>
            <button :disabled="condition_disable"
                  class="btn btn-complete m-r-10" type="button" @click="saveFurusatoCoupon($consts.INPUT.TYPE_DRAFT)" v-else>
              {{ $t('common.draft_btn') }}
            </button>
          </div>
          <button :disabled="condition_disable"
                  class="btn btn-complete m-r-10" type="button" @click="saveFurusatoCoupon($consts.INPUT.TYPE_DRAFT)" v-else>
            {{ $t('common.draft_btn') }}
          </button>
        </div>
        <div class="col-md-12 col-lg-12">&nbsp;</div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import ROUTES from "@consts/routes";
  import {AppDateTime, AppNum} from "../../../services/utils";
  export default {
    components: {
    },
    data() {
      const id = this.$route.params.id
      return {
        furusato_coupon: {
          id: "",
          full_city_id: [],
          commodity_id: [],
          status: "",
          code: "",
          name: "",
          price: "",
          use_type: this.$consts.INPUT.TYPE_DISCOUNT_DEFAULT,
          publish_num: "",
          publish_at: "",
          expire_at: "",
        },
        delete: {
          name: "",
          key_1: "",
          key_2: ""
        },
        validate:"",
        submit: false,
        freezeObj: {},
        firstLoading: true,
        options_cities: [],
        options_commodities: [],
        options_years: [],
        options_months: [],
        options_publish_days: [],
        options_expire_days: [],
        options_hours: [],
        options_minutes: [],
        options_nums: [],
        idEditing: id,
        owners : []
      }
    },
    computed: {
      time_publish() {
        return moment(this.furusato_coupon.publish_at_yy + '/' + this.furusato_coupon.publish_at_mm + '/' + this.furusato_coupon.publish_at_dd + ' ' +
          this.furusato_coupon.publish_at_hour + ':' + this.furusato_coupon.publish_at_minute).valueOf();
      },
      time_expire() {
        return moment(this.furusato_coupon.expire_at_yy + '/' + this.furusato_coupon.expire_at_mm + '/' + this.furusato_coupon.expire_at_dd + ' ' +
          this.furusato_coupon.expire_at_hour + ':' + this.furusato_coupon.expire_at_minute).valueOf();
      },
      publishDateIsValid() {
        let publishDate = moment(this.furusato_coupon.publish_at_yy + '/' + (this.furusato_coupon.publish_at_mm > 9 ? this.furusato_coupon.publish_at_mm :
          '0' + this.furusato_coupon.publish_at_mm) + '/' + (this.furusato_coupon.publish_at_dd > 9 ? this.furusato_coupon.publish_at_dd :
          '0' + this.furusato_coupon.publish_at_dd), "YYYY/MM/DD", true)
        return publishDate.isValid();
      },
      expireDateIsValid() {
        let expireDate = moment(this.furusato_coupon.expire_at_yy + '/' + (this.furusato_coupon.expire_at_mm > 9 ? this.furusato_coupon.expire_at_mm :
          '0' + this.furusato_coupon.expire_at_mm) + '/' + (this.furusato_coupon.expire_at_dd > 9 ? this.furusato_coupon.expire_at_dd :
          '0' + this.furusato_coupon.expire_at_dd), "YYYY/MM/DD", true)
        return expireDate.isValid();
      },
      condition_disable(){
        return ((!this.valid && this.validated) || (this.time_publish > this.time_expire && this.submit == true) || (this.time_expire < new Date().valueOf() && this.submit == true) || ((this.publishDateIsValid == false || this.expireDateIsValid == false) && this.submit == true))
      }
    },
    async created() {
      await this.getFullCities()
      this.options_years = await AppDateTime.getYears()
      this.options_months = await AppDateTime.getMonths()
      await this.getPublishDays(this.furusato_coupon.publish_at_dd)
      await this.getExpireDays()
      this.options_hours = await AppDateTime.getHours()
      this.options_minutes = await AppDateTime.getMinutes()
      this.options_nums = await AppNum.getNums()
    },
    watch: {
      'furusato_coupon.full_city_id': function(value, oldValue) {
        if (value != oldValue && value != '') {
          this.getProductList(value, this.$route.params.id)
        }
      }
    },
    mounted: function () {
      let params = this.$route.params;

      if (params.id) {
        this.$request.get(this.$consts.API.ADMIN.FURUSATO_COUPON_DATA_EDIT(params.id)).then(res => {
          if (res.hasErrors()) {
            if (res.status === 'error') {
              this.$appNotice.error( res.data.error_msg !== undefined ? res.data.error_msg : this.$t('common.error'));
            }
          } else {
            this.furusato_coupon = res.data.data
            let publish_at = new Date(res.data.data.publish_at)
            let expire_at = new Date(res.data.data.expire_at)
            this.furusato_coupon.publish_at_yy = publish_at.getFullYear()
            this.furusato_coupon.publish_at_mm = publish_at.getMonth() + 1
            this.furusato_coupon.publish_at_dd = publish_at.getDate()
            this.furusato_coupon.publish_at_hour = publish_at.getHours() <= 9 ? '0' + publish_at.getHours() : publish_at.getHours()
            this.furusato_coupon.publish_at_minute = publish_at.getMinutes() <= 9 ? '0' + publish_at.getMinutes() : publish_at.getMinutes()
            this.furusato_coupon.expire_at_yy = expire_at.getFullYear()
            this.furusato_coupon.expire_at_mm = expire_at.getMonth() + 1
            this.furusato_coupon.expire_at_dd = expire_at.getDate()
            this.furusato_coupon.expire_at_hour = expire_at.getHours() <= 9 ? '0' + expire_at.getHours() : expire_at.getHours()
            this.furusato_coupon.expire_at_minute = expire_at.getMinutes() <= 9 ? '0' + expire_at.getMinutes() : expire_at.getMinutes()
            this.owners = res.data.data.owners
          }
        })
      }
    },
    methods: {
      async saveFurusatoCoupon(type) {
        this.submit = true
        if (!await this.$refs.form_create_entry.validate() || (this.time_publish > this.time_expire) ||
          (this.time_expire < new Date().valueOf()) || (this.publishDateIsValid == false || this.expireDateIsValid == false)) {
          return;
        }
        if (this.furusato_coupon.use_type == this.$consts.INPUT.TYPE_DISCOUNT_PRICE) {
          this.furusato_coupon.commodity_id = null
        } else {
          this.furusato_coupon.price = null
        }

        let saveFurusatoCouponData = {
          code: this.furusato_coupon.code,
          full_city_id: this.furusato_coupon.full_city_id,
          commodity_id: this.furusato_coupon.commodity_id,
          name: this.furusato_coupon.name,
          use_type: this.furusato_coupon.use_type,
          price: this.furusato_coupon.price,
          publish_num: this.furusato_coupon.publish_num,
          publish_at: this.furusato_coupon.publish_at_yy + '/' + this.furusato_coupon.publish_at_mm + '/' + this.furusato_coupon.publish_at_dd
          + ' ' + this.furusato_coupon.publish_at_hour + ':' + this.furusato_coupon.publish_at_minute,
          expire_at: this.furusato_coupon.expire_at_yy + '/' + this.furusato_coupon.expire_at_mm + '/' + this.furusato_coupon.expire_at_dd
          + ' ' + this.furusato_coupon.expire_at_hour + ':' + this.furusato_coupon.expire_at_minute,
          status: type
        };
        if (this.$route.params.id) {
          saveFurusatoCouponData.id = this.$route.params.id
          const res = await this.$request.patch(this.$consts.API.ADMIN.FURUSATO_COUPON_EDIT(this.$route.params.id), saveFurusatoCouponData);
          if (res.hasErrors()) {
            this.$appNotice.error( res.data.error_msg !== undefined ? res.data.error_msg : this.$t('common.error'));
          } else {
            this.$appNotice.success(this.$t('common.msg update ok'));
          }
        } else {
          const res = await this.$request.post(this.$consts.API.ADMIN.FURUSATO_COUPON_SAVE, saveFurusatoCouponData);
          if (res.hasErrors()) {
            this.$appNotice.error( res.data.error_msg !== undefined ? res.data.error_msg : this.$t('common.error'));
          } else {
            this.$appNotice.success(this.$t('common.msg create ok'));
            await this.$router.push({name: ROUTES.ADMIN.FURUSATO_COUPON_UPDATE, params: {id: res.data.data.id} });
          }
        }
      },
      confirmCancel() {
        this.$refs.modal_confirm_cancel.show();
      },
      redirectBack() {
        if (this.$store.state.setting.prevRouter.name === this.$consts.ROUTES.ADMIN.FURUSATO_COUPON) {
          this.$router.go(-1);
        } else {
          this.$router.pushByName(this.$consts.ROUTES.ADMIN.FURUSATO_COUPON);
        }
      },
      async getFullCities() {
        const res = await this.$request.get(this.$consts.API.ADMIN.TOUR_FULL_CITIES, {});
        if (!res.hasErrors()) {
          this.options_cities = res.data.data;
        }
      },
      getPublishDays(month) {
        let days = new Date(new Date().getFullYear(), month, 0).getDate()
        this.options_publish_days = []
        for(let i = 1 ; i <= days ; i++) {
          this.options_publish_days.push({"id": i, "name": i})
        }
      },
      getExpireDays(month) {
        let days = new Date(new Date().getFullYear(), month, 0).getDate()
        this.options_expire_days = []
        for(let i = 1 ; i <= days ; i++) {
          this.options_expire_days.push({"id": i, "name": i})
        }
      },
      handleInput(use_type) {
        this.furusato_coupon.use_type = use_type
      },
      async getProductList(full_city_id, idCoupon) {
        if(full_city_id) {
          await this.$request.get(this.$consts.API.ADMIN.UI_HELPER_PRODUCT_LIST, {
            fullCityId: full_city_id, idCoupon: idCoupon
          }).then(res => {
            let products = res.data.products;
            if (products.length !== 0) {
              this.options_commodities = products;
            } else {
              this.options_commodities = []
            }
          })
        }
      },
    }
  }
</script>

<style scoped>
  .btn-spacing-bottom {
    padding-bottom: 50px;
  }
  .label-datetime-margin {
    margin: 2% 2% 0 0 !important;
  }

  .col-15-coupon-datetime{
    flex: 0 0 15.5% !important;
    max-width: 15.5% !important;
  }

  .button-disable{
    background-color: #858585 !important;
    border: 1px solid gray !important;
  }
  .button-disable:hover{
    background-color: #858585 !important;
  }
  .button-disable:active{
    background-color: #858585 !important;
  }
</style>
